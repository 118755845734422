import { Component, ElementRef, HostListener, OnInit, ViewChild, Inject, PLATFORM_ID, TemplateRef, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

import { environment } from '../../environments/environment';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { HPLAllocationService } from './services/trim/allocation/allocation.service';
import { Allocation } from './services/trim/allocation/allocation';
import { isPlatformBrowser } from '@angular/common';

import { TRIMS } from './services/trim/trim-data';

import { HelperService } from '../services/helper.service';
import { AdobeTMService } from '../services/adobe-tm.service';
import { GtmService } from '../services/gtm-tracking.service';
import { UserService } from '../user/user.service';

import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';


@Component({
  selector: 'dg-horsepower-locator',
  templateUrl: './horsepower-locator.component.html',
  styleUrls: ['./horsepower-locator.component.less']
})
export class HorsepowerLocatorComponent implements OnInit, OnDestroy {

  // Toggle state once Durango program launches.
  comingSoon: boolean = false;

  // Toggle available vehicle radio inputs on initial search form
  availableDurango: boolean = true;
  availableCharger: boolean = true;

  model: string;
  zipcode: string;
  zipcodeRadius: string;
  maxResults: string = '100';
  currentFormData;
  configId: number = 0;

  results: Array<Allocation>;

  // here to retain the results of ALL model results even once you select a trim
  allModelResults: Array<Allocation>;

  isSearchStarted: boolean;
  isInitialSearchCompleted: boolean;
  showResults: boolean;
  availableOrders: number = 0;

  showOnlyPowerBrokers: boolean = false;

  // boolean value to store the status of whether a trim has been selected, which will let the user download results or not
  isTrimSelected: boolean = false;

  lastUpdatedDate: string;

  trimLink: string;
  trimType: string;
  modelLink: string;
  trimLinkText: string;

  //for the video modal
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @ViewChild('videoModal', { static: true }) public videoModal: TemplateRef<any>;
  videoModalRef: BsModalRef;
  
  // Disclaimer Modal
  @ViewChild('disclaimerModal', { static: true }) public disclaimerModal: TemplateRef<any>;
  disclaimerModalRef: BsModalRef;

  // link data for models
  modelBuildLinks: any = {
    Durango: '/assets/docs/horsepower-locator/2024-dodge-durango-srt-392-alchemi-special-edition.pdf',
    //Needs updated to Daytona link
    ChargerDaytonaPDF: '/assets/docs/horsepower-locator/2024-dodge-durango-srt-392-alchemi-special-edition.pdf',
    ChargerDaytonaConfigure: 'https://www.dodge.com/next-gen-charger/configurator.html',
    Challenger: 'https://www.dodge.com/bmo.challenger.2023.html#/models/2023/challenger?app=bmo&pageType=vehiclepage&vehicle=challenger&year=2023',
    Charger: 'https://www.dodge.com/bmo.charger.2023.html#/models/2023/charger?app=bmo&pageType=vehiclepage&vehicle=charger&year=2023',
    charger1: "/assets/docs/horsepower-locator/1-dodge-charger-daytona-rt.pdf",
    charger2: "/assets/docs/horsepower-locator/2-dodge-charger-daytona-rt.pdf",
    charger3: "/assets/docs/horsepower-locator/3-dodge-charger-daytona-scat-pack.pdf",
    charger4: "/assets/docs/horsepower-locator/4-dodge-charger-daytona-scat-pack.pdf",
    charger5: "/assets/docs/horsepower-locator/5-dodge-charger-daytona-scat-pack.pdf",
    charger6: "/assets/docs/horsepower-locator/6-dodge-charger-daytona-scat-pack.pdf"
  };

  // FormGroup for the initial search
  initialSearchForm = new FormGroup({
    initialZipcode: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
    initialModel: new FormControl('Charger Daytona 2-Door', Validators.required)
  });

  errorMsg: string;
  zipcodeErrorMsg = "That's not a real zip code, and we both know it. Try again.";
  generalErrorMsg = "We're staging for the next round of action. <br> Check back soon!";

  resetForSearch(){
    this.isSearchStarted = true;
    this.showResults = false;
    this.errorMsg = '';
  }

  resetInitialSearchForm() {
    this.initialSearchForm.reset({
      initialZipcode: '',
      initialModel: 'Charger Daytona 2-Door'
    });
  }

  onModelSelected(formData, max, offset) {
    this.resetForSearch();
    this.isTrimSelected = false;
    // set this component's model to the newly selected model so it can be passed to the dealercard
    this.model = formData.model;
    this.currentFormData = formData;

    // track the model searched in UA
    this.sendModelDataUA(formData.model);

    this.scrollToTopOfList();

    this.allocationService.getAllocations(formData.zipcode, formData.model, formData.zipcodeRadius, undefined, this.showOnlyPowerBrokers, max, offset)
    .pipe(
      catchError(error => {
        this.setUnavailableErrorMessage();
        return of([]);
      }))
    .subscribe(data => {
      if (!data.status && data.dealerships) {
        this.allModelResults = data.dealerships;
        this.availableOrders = data.availableOrders;
        this.results = data.dealerships;
        this.setModelBuildLink(formData.model);
        this.showResults = true;
        this.lastUpdatedDate = new Date(data.dealerships[0].lastUpdated).toLocaleDateString();
      } else if (Array.isArray(data) && data[0] === "Please select a valid zipcode.") {
        this.resetInitialSearchForm();
        this.errorMsg = this.zipcodeErrorMsg;
        this.isInitialSearchCompleted = false;
      } else {
        this.resetInitialSearchForm();
        this.isInitialSearchCompleted = false;
      }
    })

  }

  onNewAreaSearch(formData, max, offset) {
    this.resetForSearch();
    //set these values when the new area radius or zipcode is entered so it can be used if a model changes later or a trim
    this.zipcode = formData.zipcode;
    this.zipcodeRadius = formData.zipcodeRadius;
    this.currentFormData = formData;

    this.allocationService.getAllocations(formData.zipcode, formData.model, formData.zipcodeRadius, formData.trimName, this.showOnlyPowerBrokers, max, offset)
    .pipe(
      catchError(error => {
        this.setUnavailableErrorMessage();
        return of([]);
      }))
    .subscribe(data => {
      if (!data.status && data.dealerships) {
        this.results = data.dealerships;
        this.availableOrders = data.availableOrders;
        this.showResults = true;
      } else if (Array.isArray(data) && data[0] === "Please select a valid zipcode.") {
        this.resetInitialSearchForm();
        this.isInitialSearchCompleted = false;
        this.errorMsg = this.zipcodeErrorMsg;
        this.initialSearchForm.controls['initialZipcode'].setErrors({'invalid': true});
      } else {
        // this.initialSearchForm.reset();
        this.isInitialSearchCompleted = false;
      }
      this.scrollToTopOfList();
    })
  }

  // does search on trim from the child component value
  onTrimSelected(formData, max, offset) {
    this.resetForSearch();
    this.currentFormData = formData;

    // send trim data to UA for this event
    this.sendTrimDataUA(formData.model, formData.trimName);

    this.scrollToTopOfList();

    this.allocationService.getAllocations(formData.zipcode, formData.model, formData.zipcodeRadius, formData.trimName, this.showOnlyPowerBrokers, max, offset)
    .pipe(
      catchError(error => {
        this.setUnavailableErrorMessage();
        return of([]);
      }))
    .subscribe(data => {
      if (!data.status && data.dealerships) {
      this.results = data.dealerships;
      this.availableOrders = data.availableOrders;
      //testing this out
      this.setBuildAndPriceLink(formData.model, formData.trimName)
      this.showResults = true;
      this.isTrimSelected = true;
      } else if (Array.isArray(data) && data[0] === "Please select a valid zipcode.") {
        this.resetInitialSearchForm();
        this.errorMsg = this.zipcodeErrorMsg;
        this.isInitialSearchCompleted = false;
      } else {
        this.resetInitialSearchForm();
        this.isInitialSearchCompleted = false;
      }
    })
  }

  // updates config id for use in presenting correct Specs PDF download
  onUpdatedConfigId(event) {
    this.configId = event;
  }

  onInitialSearch(max, offset) {
    // set this instance's values from the FormGroup
    this.model = this.initialSearchForm.value.initialModel;
    this.zipcode = this.initialSearchForm.value.initialZipcode;

    // track the model search in UA
    this.sendModelDataUA(this.initialSearchForm.value.initialModel);

    // Set initial request to currentFormData for use in lazy loading if no other options are selected
    this.currentFormData = {
      zipcode: this.zipcode,
      model: this.model,
      radius: undefined,
      trim: undefined,
      powerBroker: undefined
    }
    this.isSearchStarted = true;
    // reset the error message
    this.errorMsg = '';

    this.allocationService.getAllocations(this.initialSearchForm.value.initialZipcode, this.initialSearchForm.value.initialModel, undefined, undefined, undefined, max, offset)
      .pipe(
        catchError(error => {
          // if an error is caught, set a friendly error message that can be displayed on the frontend
          this.setUnavailableErrorMessage();
          this.trackInitialSearch(null);
          return of([]);
        }))
      .subscribe(data => {
        // check for status property being present. If it is NOT present AND the dealerships array is present, continue normally and process the results
        if ( !data.status && data.dealerships ) {
          if (this.model === 'Charger Daytona 2-Door') {
            this.openDisclaimerModal();
          }
          
          this.allModelResults = data.dealerships;
          this.results = data.dealerships;
          this.availableOrders = data.availableOrders;
          this.isInitialSearchCompleted = true;
          this.setModelBuildLink(this.initialSearchForm.value.initialModel);
          this.showResults = true;
          this.trackInitialSearch();

          if (data.dealerships.length > 0) {
            // grab the last updated date from the first dealership in the array
            this.lastUpdatedDate = new Date(data.dealerships[0].lastUpdated).toLocaleDateString();
          }
        } else if (Array.isArray(data) && data[0] === "Please select a valid zipcode.") {
          this.resetInitialSearchForm();
          this.errorMsg = this.zipcodeErrorMsg;
          this.trackInitialSearch(true);
          this.initialSearchForm.controls['initialZipcode'].setErrors({'invalid': true});
        } else {
          // reset the form in the case of a general API error
          // We're going to display an error message instead now
          // this.initialSearchForm.reset();
        }
        this.scrollToTopOfList();
      })
  }

  setModelBuildLink(model: string){
    if(model === 'Durango'){
      this.trimLink = this.modelBuildLinks.Durango;
      this.modelLink = this.modelBuildLinks.Durango;
    } else if(model === 'Charger Daytona 2-Door'){
      this.modelLink = this.modelBuildLinks.ChargerDaytonaConfigure;

      switch (this.configId) {
        case 1:
          this.trimLink = this.modelBuildLinks.charger1;
          break;
        case 2:
          this.trimLink = this.modelBuildLinks.charger2;
          break;
        case 3:
          this.trimLink = this.modelBuildLinks.charger3;
          break;
        case 4:
          this.trimLink = this.modelBuildLinks.charger4;
          break;
        case 5:
          this.trimLink = this.modelBuildLinks.charger5;
          break;
        case 6:
          this.trimLink = this.modelBuildLinks.charger6;
          break;
        default:
          this.trimLink = '';
      }
    }
    this.setBuildLinkText();
  }

  // on click of show power brokers checkbox (make another request for it)
  onShowPowerBrokers(max, offset) {
    this.resetForSearch();
    this.showOnlyPowerBrokers = !this.showOnlyPowerBrokers;

    // Track changes on checkbox
    this.adobeTMService.pushToDataLayer({
      "event":"interaction-click",
      "interaction": {
        "site":"dodgegarage",
        "type":"func",
        "page":"shopping-tools:horsepower-locator",
        "location":"filtering-options:show-power-brokers",
        "description":"power-brokers-only:checked:" + this.showOnlyPowerBrokers
      }
    });


    // check if powerBrokers is being checked
    if(this.showOnlyPowerBrokers === true) {
      // do the power broker UA push
      this.helperService.trackGAEvent('hpl', 'Click', 'power-brokers');
    }

    this.allocationService.getAllocations(this.currentFormData.zipcode, this.currentFormData.model, this.currentFormData.zipcodeRadius, this.currentFormData.trimName, this.showOnlyPowerBrokers, max, offset)
    .pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = this.generalErrorMsg;
        } else {
          this.errorMsg = this.generalErrorMsg;
        }
        return of([]);
      }))
    .subscribe(data => {
      if (!data.status && data.dealerships){
        this.results = data.dealerships;
        this.availableOrders = data.availableOrders;
        this.showResults = true;
      } else if (Array.isArray(data) && data[0] === "Please select a valid zipcode.") {
        this.resetInitialSearchForm();
        this.errorMsg = this.zipcodeErrorMsg;
        this.isInitialSearchCompleted = false;
      } else {
        this.resetInitialSearchForm();
        this.isInitialSearchCompleted = false;
      }
      this.scrollToTopOfList();
    })
  }

  // need to get the data for the model & trim and price link and return the link
  // trimName could be empty or not set and there needs to be a build link for just challenger / charger since that is possible to click
  setBuildAndPriceLink(modelName: string, trimCode: string = null) {
    //todo handle it right here is trimName === null
    const trim = TRIMS.find(t => t.trimCode === trimCode && t.model === modelName );

    // probably don't need the else if here, should just be if === 'trim' and then then else is 'se'
    if (trim.type === 'trim'){
      this.trimLink = trim.link.replace('ZIPCODE', this.zipcode);
      this.trimType = trim.type;
    } else if (trim.type === 'se'){
      this.trimLink = trim.link;
      this.trimType = trim.type;
    } else {
      this.trimLink = null;
    }

    this.setBuildLinkText(trimCode);

  }

  setBuildLinkText(trimCode: string = null) {
    // set 'Build and Price', 'Download Specs', or 'Options and Specs' (for a single, special Challenger model) based on the trim type and the model
    if(this.trimType === 'se' && trimCode === 'DR2227QAAMPX8'){
       this.trimLinkText = 'Options + Specs';
    } else if(this.trimType === 'se'){
      this.trimLinkText = 'Build and Price';
    } else {
      this.trimLinkText = 'Download Specs';
    }
  }

  getTrimLabel(modelName: string, trimCode: string) {
    const trim = TRIMS.find(t => t.trimCode === trimCode && t.model === modelName);
    if (trim != undefined) {
      return trim.label;
    } else {
      return '';
    }
  }

  openVideoModal(event) {
    event.preventDefault();
    this.videoModalRef = this.modalService.show(this.videoModal, {class: 'neverlift-video'});
    // this.helperService.trackGAEvent('Category', 'Action', 'Label');
  }

  closeVideoModal() {
    this.videoModalRef.hide();
    // this.helperService.trackGAEvent('Category', 'Action', 'Label');
  }

  // setup and pass this value for analytics
  sendModelDataUA(modelInput: string) {
    const modelUAString = "model-" + modelInput.toLowerCase();
    this.helperService.trackGAEvent('hpl', 'Click', modelUAString);
  }

  sendTrimDataUA(model: string, trimCode: string) {
    const modelUAString = "model-" + model.toLowerCase();
    this.helperService.trackGAEvent('hpl', 'Click', modelUAString + "-trim-" + trimCode )
  }

  constructor(
    private allocationService: HPLAllocationService,
    public title: Title,
    public metaService: Meta,
    public sanitizer: DomSanitizer,
    private router: Router,
    private modalService: BsModalService,
    public helperService: HelperService,
    public userService: UserService,
    private adobeTMService: AdobeTMService,
    private gtmService: GtmService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isInitialSearchCompleted = false;
    this.isSearchStarted = false;
    this.showResults = false;

    //meta info
    title.setTitle('Dodge Horsepower Locator');

    metaService.updateTag({ name: 'description', content: 'Search for your dream Last Call Dodge Durango to connect with a dealer who has allocation to place your order, exactly how you want it.' })
    metaService.updateTag({ property: 'og:description', content: 'Search for your dream Last Call Dodge Durango to connect with a dealer who has allocation to place your order, exactly how you want it.' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Dodge Horsepower Locator' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: environment.url + '/assets/images/hpl-logo-black-base.webp' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: environment.url + '/assets/images/hpl-logo-black-base.webp' })
    metaService.updateTag({ name: 'twitter:image:width', content: '768' })
    metaService.updateTag({ name: 'twitter:image:height', content: '413' })
  }

  // Styling to allow for full black background and fill height since content is slim. Can be removed once we're passed Coming Soon state.
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId) && this.comingSoon) {
      let mainBody = document.querySelector('main');
      mainBody.classList.add('bg-black');
      mainBody.style.display = 'flex';
      mainBody.style.flexDirection = 'column';
    }

    this.trackPageView();
  }
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId) && this.comingSoon) {
      let mainBody = document.querySelector('main');
      mainBody.classList.remove('bg-black');
      mainBody.style.display = 'block';
      mainBody.style.flexDirection = '';
    }
  }

  // lazy loading on scroll
  @ViewChild('dealerCardContainer') dealerCardContainer:ElementRef;
  cardCounter = Number(this.maxResults);
  cardsPerLoad = Number(this.maxResults);
  cardsFromBottom = 5;

  scrollToTopOfList() {
    if (isPlatformBrowser(this.platformId)) {
      let heroAreaRect = document.querySelector('#hero-area').getBoundingClientRect();
      let navigationRect = document.querySelector('#primary-nav').getBoundingClientRect();
      let offset = window.innerWidth < 992 ? 65 : 35;


      window.scrollTo({top: heroAreaRect.height - (navigationRect.height + offset)});
    }
  }

  printResults() {
    //fire ga event for download results
    this.helperService.trackGAEvent('hpl', 'Click', 'download');
    this.trackTop100();

    if (isPlatformBrowser(this.platformId)) {
      window.print();
    }
  }

  @HostListener('window:scroll')
  onWindowScroll(): void {
    let windowTop = typeof window.scrollY === 'undefined' ? window.pageYOffset : window.scrollY;

    // Check to see if results are visible on page before attempting
    if (this.dealerCardContainer != undefined) {
      let container = this.dealerCardContainer.nativeElement;
      let cards = container.querySelectorAll('hpl-dealer-card').length > 0 ? container.querySelectorAll('hpl-dealer-card') : null;
      let card = container.querySelectorAll('hpl-dealer-card').length > 0 ? cards[0] : null;
      let containerHeight = container.getBoundingClientRect().height;
      let cardHeight =container.querySelectorAll('hpl-dealer-card').length > 0 ? card.getBoundingClientRect().height : 0;

      // Continue showing cards until counter catches up to length to surpress annoying scrolling errors.
      if ((cards != null && cards.length >= this.cardCounter) && containerHeight - (cardHeight * this.cardsFromBottom) < windowTop) {
        this.cardCounter = this.cardCounter + this.cardsPerLoad;

        //this.dealerCardContainer.nativeElement.querySelector(`hpl-dealer-card:nth-child(n + ${this.cardCounter})`).classList.add('show');
        this.allocationService.getAllocations(this.currentFormData.zipcode, this.currentFormData.model, this.currentFormData.radius, this.currentFormData.trimName, this.currentFormData.powerBroker, this.maxResults, this.cardCounter.toString()).subscribe(data => {
          this.allModelResults = data.dealerships;
          data.dealerships.forEach(item => {
            this.results.push(item);
          });
          this.isInitialSearchCompleted = true;
          this.showResults = true;
        })
      }
    }
  }

  setUnavailableErrorMessage() {
    this.errorMsg = this.generalErrorMsg;
    console.error("An error occurred with the API request.");
    this.initialSearchForm.controls['initialZipcode'].setErrors({'unavailable': true});
  }


  // Adobe Tracking
  getDeviceType(): string {
    if (isPlatformBrowser(this.platformId)) {
      const width = window.innerWidth;

      if (width < 576) {
        return 'Mobile';
      } else if (width >= 576 && width < 768) {
        return 'Phablet';
      } else if (width >= 768 && width < 1200) {
        return 'Tablet';
      } else {
        return 'Desktop';
      }
    }
  }

  trackPageView(): void {
    const data = {
      "event": "page-load",
      "page": {
        "type": "dodgegarage",
        "name": "horsepower-locator"
      },
      "platform": {
        "technology": "dodge"
      },
      "user": {
        "language": "en-US",
        "responsiveState": this.getDeviceType(),
        "authentication": this.userService.userModel.isLoggedIn,
        "zipCode": ""
      }
    };
    this.adobeTMService.pushToDataLayer(data);
  }

  trackVehicleSelection(): void {
    const data = {
      "event":"form-submit",
      "form": {
        "formDescription":"non-lead",
        "formType":"pre-qualification",
        "displayType":"page",
        "displayFormat":"in-page"
      }
    };
    this.adobeTMService.pushToDataLayer(data);
  }

  trackDealerInventoryLink(): void {
    const data = {
      "event":"interaction-click",
      "interaction": {
        "site":"dodgegarage",
        "type":"nav",
        "page":"shopping-tools:horsepower-locator",
        "location":"hero-panel",
        "description":"sni-t1"
      }
    };
    this.adobeTMService.pushToDataLayer(data);
  }

  trackInitialSearch(error: boolean = false): void {
    let data = {};
    let gtmData = {};

    if (error === true) {
      data = {
        "event":"error-display",
        "error": {
          "message":"invalid-zip",
          "type": "form validation"
        }
      }
      gtmData = {
        "event":"hpl.initial_search_form_submission.error"
      }
    }else if (error === null) {
      data = {
        "event":"error-display",
        "error": {
          "message":"system-error",
          "type": "form validation"
        }
      }
    }else {
      data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"func",
          "page":"shopping-tools:horsepower-locator",
          "location":"hero-panel",
          "description":"submit-zip"
        },
        "user": {
          "zipCode": this.zipcode
        },
        "vehicle": {
          "make": "dodge",
          "model": this.model
        }
      };

      gtmData = {
        'event': 'hpl.initial_search_form_submission.success',
        'hpl.initial_search_form_submission.model': this.model,
        'hpl.initial_search_form_submission.zip': this.zipcode
      }
    }

    this.adobeTMService.pushToDataLayer(data);
    this.gtmService.pushEvent(gtmData);
  }

  trackTop100(): void {
    const data = {
      "event":"interaction-click",
      "interaction": {
        "site":"dodgegarage",
        "type":"cta",
        "page":"shopping-tools:horsepower-locator",
        "location":"dealer-results",
        "description":"download-top-100-results"
      }
    };
    this.adobeTMService.pushToDataLayer(data);
  }

  // Disclaimer modal functionlaity
  openDisclaimerModal() {
    this.disclaimerModalRef = this.modalService.show(this.disclaimerModal, Object.assign({}, { class: 'modal-card' }));
  }

  trackEvCertified(): void {
    const data = {'event': 'hpl.charger_ev_dealers_pdf'};
    this.gtmService.pushEvent(data);
  }

}
