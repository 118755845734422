import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'meet-ceo-dodge',
  templateUrl: './meet-ceo-dodge.component.html',
  styleUrls: ['./meet-ceo-dodge.component.scss']
})
export class MeetCeoDodgeComponent implements OnInit {

  constructor(
    public route: ActivatedRoute,
    public title: Title,
    public metaService: Meta,
    private router: Router
  ) {
    title.setTitle( 'Meet the CEO of Dodge | Dodge Garage' );

    metaService.updateTag({ name: 'description', content: 'Meet the CEO of Dodge. Dodge GarageCast video' })
    metaService.updateTag({ property: 'og:description', content: 'Meet the CEO of Dodge. Dodge GarageCast video' })
    metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' })
    metaService.updateTag({ property: 'og:title', content: 'Meet the CEO of Dodge | Dodge Garage' })
    metaService.updateTag({ property: 'og:type', content: '' })
    metaService.updateTag({ property: 'og:image', content: '' })
    metaService.updateTag({ property: 'og:url', content: environment.url + this.router.url })
    metaService.updateTag({ name: 'twitter:card', content: 'summary' })
    metaService.updateTag({ name: 'twitter:image', content: '' })
    metaService.updateTag({ name: 'twitter:image:width', content: ''})
    metaService.updateTag({ name: 'twitter:image:height', content: ''})
  }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    
  }

}
