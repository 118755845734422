<div class="dealer-card bg-white">
  <div class="orders-dealer-name">
    <div class="available-orders">
      <span class="available-number">
        {{ quantity.toLocaleString('en') }}
      </span>
      <span class="smaller-label">AVAILABLE</span>
      <span class="smaller-label">{{ quantity === 1 ? 'ORDER' : 'ORDERS' }}</span>
    </div>
    <div class="dealership-name-pb-logo">
      <p class="dealership-name font-bold">
        {{ dealerName }}
      </p>
      <img *ngIf="isPowerBroker" src="/assets/images/logos/power-brokers-logo-sm.png" class="power-broker-img" alt="Dodge Power Brokers Logo">
    </div>

  </div>
  <div class="dealership-info">
    <a (click)="trackCardLinks('address')" href="https://maps.google.com/maps?daddr={{address}}+{{city}}+{{state}}+{{postalCode}}" target="_blank">
      <span>
        <i class="fa fa-map-marker"></i>{{ address }}
        <br class="address-break">
        {{ city }}, {{ state }} {{ postalCode}}
      </span>
    </a>
    <span><i class="fa fa-map"></i>&nbsp;{{ distance | number: '1.0-0'}} Miles Away</span>
    <a (click)="trackCardLinks('phone')" href="tel:+{{ phoneNumber | phoneNumber }}"><i class="fa fa-phone"></i>&nbsp;{{ phoneNumber | phoneNumber }}</a>
  </div>
  <hr>
  <div class="cta-row">
    <div class="view-orders-link">
      <hpl-dealer-availability [model]="model" [dealercode]="dealercode" [modelLink]="modelLink" [dealerName]="dealerName" [address]="address" [city]="city" [state]="state" [postalCode]="postalCode" [phoneNumber]="phoneNumber" [distance]="distance" [isPowerBroker]="isPowerBroker" [website]="website"></hpl-dealer-availability>
    </div>
    <div class="pb-img">
      <img *ngIf="isPowerBroker" src="/assets/images/logos/power-brokers-logo-sm.png" class="power-broker-img" alt="Dodge Power Brokers Logo">
    </div>
    <!-- Remove *ngIf="trimLinkText !== 'Download Specs'" if PDF's are available for Special Editions-->
    <ng-container *ngIf="!hideSpecs">
      <a 
        *ngIf="model !== 'Durango' && configId === 0"
        (click)="handleDownloadSpecsClicks($event)"
        class="btn btn-primary disable"
        href="#"
        [tooltip]="'Please select a trim to download specs'"
        placement="top"
        container="body"  
        containerClass="hpl-tooltip"
        triggers="hover focus" 
        [adaptivePosition]="false">
        {{ (model === 'Durango' || model === 'Charger Daytona 2-Door') ? 'Download Specs' : 'Build and Price' }}
      </a>
      <a 
        *ngIf="model === 'Durango' || configId != 0"
        (click)="handleDownloadSpecsClicks($event)"
        class="btn btn-primary"
        [href]="trimLink"
        target="_blank">
        {{ (model === 'Durango' || model === 'Charger Daytona 2-Door') ? 'Download Specs' : 'Build and Price' }}
      </a>
    </ng-container>

    <a *ngIf="website.length > 0" (click)="trackCardLinks('website')" [href]="formattedWebsite(website)" target="_blank" class="btn btn-white btn-outline-primary">Dealer Site</a>
    <!-- <ng-template #noWebsite>
      <div>
        &nbsp;
      </div>
    </ng-template> -->
  </div>
</div>
