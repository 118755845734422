<div (click)="openViewOrdersyModal(viewOrdersModal, $event)" class="dealer-availability-link">
  <div class="view-inventory-link">
    <span><i class="fa fa-info-circle"></i></span><span>View All {{ model }} <span class="hide-this-break-on-mobile"><br/></span>Orders Available</span>
  </div>
</div>

<ng-template #viewOrdersModal>
  <div class="modal-availability modal-container modal-container-lg">
    <div class="modal-header">
      <button class="modal-close" (click)="closeViewOrdersModal()">
        <i class="fa fa-close"><span class="sr-only">close</span></i>
      </button>
      <p>All {{ model }} Orders Available</p>
    </div>
    <div class="modal-body">
      <p class="dealer-name font-bold">{{dealerName}}</p>
      <div class="dealership-info">
        <a (click)="trackCardLinks('address')" href="https://maps.google.com/maps?daddr={{address}}+{{city}}+{{state}}+{{postalCode}}" class="no-underline-link" target="_blank">
          <i class="fa fa-map-marker"></i>
          <span>{{ address }}<br>{{ city }}, {{ state }} {{ postalCode}}</span>
        </a>
        <span><i class="fa fa-map"></i>{{ distance | number: '1.0-0'}} Miles Away</span>
        <a (click)="trackCardLinks('phone')" href="tel:+{{ phoneNumber | phoneNumber }}"><i class="fa fa-phone"></i>{{ phoneNumber | phoneNumber }}</a>
      </div>
      <div class="dealer-table">
        <div *ngIf="isDealerDataLoaded; else loading" class="dealer-table-content">
          <div *ngFor="let trim of trimAllocations" class="trim-item">
            <span [innerHTML]="trim.trimLabel"></span> <span>{{ trim.quantity }} Available {{ trim.quantity === 1 ? 'Order' : 'Orders'}}</span>
          </div>
        </div>
        <div class="dealership-ctas">
          <div *ngIf="isPowerBroker" class="pb-img-container">
            <img src="/assets/images/logos/power-brokers-logo-sm.png" class="power-broker-img"
              alt="Dodge Power Brokers Logo">
          </div>
          <a *ngIf="model === 'Charger Daytona 2-Door'" class="btn btn-primary" [href]="modelLink" (click)="helperService.trackGAEvent('hpl', 'Click', 'bp'); trackBuildAndPrice(model)" target="_blank">
            Configure Yours
          </a>
          <a *ngIf="website.length > 0" (click)="trackCardLinks('website')" [href]="formattedWebsite(website)" target="_blank"
            class="btn btn-white btn-outline-primary">Dealer Site</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading-area">
    <div class="loading-icon">
      <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
