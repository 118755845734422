¡<div class="site-wrapper" style="margin-top: 3rem;">
	<div class="container-fluid">
		<div class="article-content hidden">
			<div class="row">
				<div class="col-sm-12">
					<h1 class="heading1">Downloads </h1>
				</div>
			</div>
		</div>
	</div>

	<!-- <div class="holiday-cards">
		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">
					<h2 class="h3 heading2 text-uppercase download-heading">Dodge Holiday Cards</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12">
					<div class="stencil-container">
						<holiday-cards></holiday-cards>
					</div>
				</div>
			</div>
		</div>
	</div> -->

	<div class="container-fluid">
		<div class="article-content">
			<div class="row">
				<div class="col-sm-12">

					<tabset class="dg-tabs stack-xs">
<!--					  <tab id="pumpkin" [active]="activeTab == 'pumpkin'" (selectTab)="updateHashOnTabChange('pumpkin')">-->
<!--					    <ng-template tabHeading>-->
<!--					      <span>Pumpkin Carving Stencils</span>-->
<!--					    </ng-template>-->
<!--					    <p><a href="/assets/images/stencils/pumpkin-carving-stencils.pdf" target="_blank" rel="noopener"-->
<!--					        class="btn btn-primary ga-event" data-ga_category="Coloring Sheets" data-ga_action="Download"-->
<!--					        data-ga_label="Full Kit">-->
<!--					        <span>Download the Whole Kit Now!</span>-->
<!--					      </a></p>-->
<!--					    <div class="row-flex">-->
<!--					      <div class="col-flex-sm-6 col-flex-md-4 col-flex-lg-3 mb-3" *ngFor="let item of stencils">-->
<!--					        <div class="cta-card">-->
<!--					          <div class="text-uppercase" [innerHTML]="item.name"></div>-->
<!--					          <img loading="lazy" src="{{ item.thumbnail }}" alt="{{ item.name }}" class="img-responsive">-->
<!--					          <a [href]="item.pdf" target="_blank" rel="noopener" class="btn btn-primary btn-block ga-event"-->
<!--					            data-ga_category="Coloring Sheets" data-ga_action="Download" attr.data-ga_label="{{ item.name }}"-->
<!--					            [title]="'Download ' + item.name + ' Coloring Sheet Now'">-->
<!--					            Download Now-->
<!--					          </a>-->
<!--					        </div>-->
<!--					      </div>-->
<!--					    </div>-->
<!--					  </tab>-->
						<tab id="wallpapers" [active]="activeTab == 'wallpapers'" (selectTab)="updateHashOnTabChange('wallpapers')">
							<ng-template tabHeading>
								<span>Wallpapers</span>
							</ng-template>
							<div class="row">
								<!--<div class="col-sm-12">
									<h2 class="heading2 text-uppercase download-heading visible-md visible-lg">Wallpapers</h2>
									<h3 class="h4 download-subheading">Select a size, right click, save image and enjoy!</h3>
								</div>-->
								<div class="col-sm-6 col-md-4 col-lg-3" *ngFor="let wallpaper of wallpapers" (deferLoad)="wallpaper.isLoaded = true">
									<div class="downloads" *ngIf="wallpaper.isLoaded">
										<img loading="lazy" src="{{ wallpaper.src.thumb }}" alt="{{ wallpaper.name }}" class="img-responsive">
										<div class="dropdown dropup" dropdown dropup="true">
											<button class="btn btn-primary" type="button" aria-haspopup="true" aria-expanded="false" dropdownToggle>
												Download Size Options
											</button>
											<ul class="dropdown-menu list-unstyled" *dropdownMenu>
												<li>
													<a *ngIf="wallpaper.src.full" class="ga-event" href="{{ wallpaper.src.full }}" target="_blank" data-ga_category="Downloads" [attr.data-ga_action]="wallpaper.name" data-ga_label="Size - Full">Full</a>
												</li>
												<li>
													<a class="ga-event" href="{{ wallpaper.src.desktop }}" target="_blank" data-ga_category="Downloads" [attr.data-ga_action]="wallpaper.name" data-ga_label="Size - Desktop">Desktop</a>
												</li>
												<li>
													<a class="ga-event" href="{{ wallpaper.src.tablet }}" target="_blank" data-ga_category="Downloads" [attr.data-ga_action]="wallpaper.name" data-ga_label="Size - Tablet">Tablet</a>
												</li>
												<li>
													<a class="ga-event" href="{{ wallpaper.src.mobile }}" target="_blank" data-ga_category="Downloads" [attr.data-ga_action]="wallpaper.name" data-ga_label="Size - Mobile">Mobile</a>
												</li>
												<li *ngIf="wallpaper.src.watch">
													<a class="ga-event" href="{{ wallpaper.src.watch }}" target="_blank" data-ga_category="Downloads" [attr.data-ga_action]="wallpaper.name" data-ga_label="Size - Smart Watch">Smart Watch</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab id="ringtones" [active]="activeTab == 'ringtones'" (selectTab)="updateHashOnTabChange('ringtones')">
							<ng-template tabHeading>
								<span>Ringtones</span>
							</ng-template>
							<div class="row">
								<div class="col-sm-12">
									<p><a class="instructions" routerLink="/downloads/ringtone-instructions">How to install ringtones</a></p>
									<!--<h2 class="heading2 text-uppercase download-heading visible-md visible-lg">Ringtones</h2>
									<h3 class="h4 download-subheading"></h3>-->
								</div>
								<div class="col-sm-12">
									<ringtones class="flex-container"></ringtones>
								</div>
							</div>
						</tab>
            <tab id="featured" [active]="activeTab == 'featured'" (selectTab)="updateHashOnTabChange('featured')">
							<ng-template tabHeading>
								<span>Birthday Cards</span>
							</ng-template>
							<div class="row">
								<!--<div class="col-sm-12">
									<h2 class="heading2 text-uppercase download-heading visible-md visible-lg">Birthday Cards</h2>
								</div>-->
								<div class="col-sm-6 col-md-4 col-lg-3" *ngFor="let item of featured" (deferLoad)="item.isLoaded = true">
									<div class="downloads" *ngIf="item.isLoaded">
										<picture>
											<img loading="lazy" [src]="item.imgSrc" [alt]="item.name" class="img-responsive">
										</picture>
										<a [href]="item.download" download class="btn btn-primary" attr.data-ga_label="{{ item.name }}" [title]="'Download ' + item.name + ' Birthday Card Now'">Download</a>
									</div>
								</div>
							</div>
						</tab>
						<tab id="fun-and-games" [active]="activeTab == 'fun-and-games'" (selectTab)="updateHashOnTabChange('fun-and-games')">
              <ng-template tabHeading>
                <span>Fun & Games</span>
              </ng-template>
              <p><a href="/assets/images/downloads/coloring-sheets/dodgegarage-fun-and-games.pdf" target="_blank" rel="noopener" class="btn btn-primary ga-event" data-ga_category="Fun and Games" data-ga_action="Download" data-ga_label="Full Kit">
            <span>Download the Whole Kit Now!</span>
            </a></p>
              <!--<div class="row">
                <div class="col-sm-7">
                  <h2 class="heading2 text-uppercase download-heading visible-md visible-lg">Coloring Sheets</h2>
                  <h3 class="h4 download-subheading">Download. Print. Color!</h3>
                </div>
                <div class="col-sm-5">

                </div>
              </div>-->
              <div class="row-flex">
                <div class="col-flex-sm-6 col-flex-md-4 col-flex-lg-3 mb-3" *ngFor="let item of coloringSheets">
                  <div class="cta-card">
                    <div class="text-uppercase" [innerHTML]="item.name"></div>
                    <img loading="lazy" src="{{ item.thumbnail }}" alt="{{ item.name }}" class="img-responsive">
                    <a [href]="item.download" target="_blank" rel="noopener" class="btn btn-primary btn-block ga-event" data-ga_category="Fun and Games" data-ga_action="Download" attr.data-ga_label="{{ item.name }}" [title]="'Download ' + item.name + ' Coloring Sheet Now'">
											Download Now
                    </a>
                  </div>
                </div>
              </div>
            </tab>
					</tabset>

				</div>
			</div>

		</div>
    <ngx-json-ld [json]="schema"></ngx-json-ld>
  </div>
</div>
